import * as React from "react"
import HomeSlide from "../components/home/HomeSlide";
import Dropdown from "../components/dropdown/Dropdown"
import Header from "../components/shared/Header";
import resourcepack from "../images/resourcepack.jpg";
import "../styles/style.scss";
import Button from "../components/button/Button";
import downloads from "../data/downloads.json";
import { useState } from "react";

// markup
const Photography = () => {
    return <>
        <title>Photography</title>
        <Header />
        <div className="photo-page-container">
            <div className="photo-card-container">
                <div className="photo-card">
                    <img src={resourcepack} alt="" />
                    <div className="photo-card-info">
                        <span className="fs-4">Title of the image</span>
                        <a href="#" className="fs-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                            </svg>
                        </a>
                    </div>
                </div>
                {/* <div className="photo-card-content-container">
                    <span>Title of the image</span>
                    <Button text="Download" dataType="clear"></Button>
                </div> */}
            </div>
        </div>
    </>;
}

export default Photography;